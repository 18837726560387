.navbar-inverse {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  box-shadow: 0px 2px 2px #e4e4e4;
}

  .navbar-inverse .navbar-brand {
      color: #1c76bc;
  }

      .navbar-inverse .navbar-brand:hover,
      .navbar-inverse .navbar-brand:focus {
          background-color: transparent;
          color: #1c76bc;
      }

img.navbar-brand {
  height: 60px;
  padding: 10px 0;
}

.navbar-brand {
  padding: 15px 20px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.col-md-8.col-md-push-2.inner-content-box, .col-md-6.col-md-push-3.inner-content-box {
  background: #fff;
  box-shadow: 0px 0px 10px #9e9e9e;
  padding: 20px;
  border-radius: 6px;
  margin-top: 50px;
  position: relative;
}


.buttons{
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  text-align: center !important;
  border-radius: 30px !important;
  padding: 0.375rem 8.5rem !important;
  margin: 10px;
}
a.opener {
  cursor: pointer;
}

.navbar-header {
  float: left;
}

.navbar-right {
  float: right !important;
}
body.modal-open, .modal-open .navbar-fixed-top, .modal-open .navbar-fixed-bottom {
  margin-right: 0px;
}
.modal {
  overflow: auto;
  overflow-y: hidden;
}
.inner-content-box h3 {
  margin: 0;
}

.inner-content-box .form-control {
  padding: 0;
}

.icon{
  margin-left: 6px;
  height: 14px;
  width: 14px;
}

.successicon{
  color: forestgreen;
  height: 90px;
  width: 90px !important;
}
.successmessage{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.centerImg {
  margin: auto;
  width: 35%;
  padding: 10px;
}
.centerTxt {
  margin: auto;
  width: 80%;
  padding: 10px;
}
@media screen and (max-width: 640px) {
  #fullTermsOfService .modal-body {
      max-height: 390px;
  }
}

@media  screen and (min-width: 992px)
{
.col-md-push-3 {
    left: 25%;
}
}

@media screen and (min-width: 992px){
  .col-md-6 {
    width: 50%;
  }
}

a:link {
  text-decoration: none;
  margin:0 10px;
}

.loaderScreen {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #122e43;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  /* Safari */
}

.termsCheckedbox{
  pointer-events: none;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media  screen and (min-width: 360px)
{
  .buttons {
    display: block !important;
    text-align: center !important;
    border-radius: 30px !important;
    padding: 0.375rem 6.5rem !important;
    margin: 10px;
  }
}

.pdf-div{
  padding: 50px;
  background-color: whitesmoke;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
}
.clickable{
  cursor: pointer;
}

